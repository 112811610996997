import axios from '@axios'
import store from './../../store'

export default {
  namespaced: true,
  state: {
    history: [],
    notifications: [],
    payments: [],
    pagination: {
      current_page: 1,
      total: 0,
      count: 20
    },
    api_statuses: [],
    users: [],
  },
  getters: {},
  mutations: {
    SET_HISTORY (state, data) {
      state.history = data
    },
    SET_PAGINATION (state, pagination) {
      Object.assign(state.pagination, pagination)
    },
    SET_USERS (state, data) {
      state.users = data
    },
    ADD_API (state, data) {
      state.users.push(data)
    },
    EDIT_API (state, data) {
      const index = state.users.findIndex((e) => e._id === data._id)
      if (index >= 0) {
        Object.assign(state.users[index], data)
      }
    },
    DELETE_API (state, id) {
      const index = state.users.findIndex((e) => e._id === id)
      if (index >= 0) {
        state.users.splice(index, 1)
      }
    },
    SET_NOTIFICATIONS (state, data) {
      state.notifications = data
    },
    SET_PAYMENTS (state, data) {
      state.payments = data
    },
    SET_STATUSES(state, data) {
      state.api_statuses = data.data;
      state.pagination = data.pagination;
    }
  },
  actions: {

    getHistory ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`api/history?${query}`)
          .then(response => {
            commit("SET_HISTORY", response.data.data)
            commit("SET_PAGINATION", response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSearchHistory ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`api/search-history?${query}`)
          .then(response => {
            commit("SET_HISTORY", response.data.data)
            commit("SET_PAGINATION", response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getViewHistory ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`api/view-history?${query}`)
          .then(response => {
            commit("SET_HISTORY", response.data.data)
            commit("SET_PAGINATION", response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getNotifications ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`checkup-notifications?${query}`)
          .then(response => {
            commit("SET_NOTIFICATIONS", response.data.data)
            commit("SET_PAGINATION", response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPayments ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`checkup-payments?${query}`)
          .then(response => {
            commit("SET_PAYMENTS", response.data.data)
            commit("SET_PAGINATION", response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getUsers ({commit}, stat = true) {
      return new Promise((resolve, reject) => {
        const userType = atob(store.state.user_type)
        const action = (userType === 'ADMIN') ? 'api/users' : 'api/view-users'
        axios.get(`${action}?stat=${stat}`)
          .then(response => {
            commit("SET_USERS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getApiStatuses ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`api/statuses?${query}`)
          .then(response => {
            commit("SET_STATUSES", response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addApi ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`api/users`, payload)
          .then(response => {
            commit("ADD_API", response.data.item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    editApi ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`api/users/${payload._id}`, payload)
          .then(response => {
            commit('EDIT_API', response.data.item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteApi ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`api/users/${id}`)
          .then(response => {
            commit("DELETE_API", id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  }
}
